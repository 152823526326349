<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmGPathCareertypeFormData && Object.keys(vmGPathCareertypeFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationname">
                    {{cvNameLabel}}</label>
                  <input
                    v-model="vmGPathCareertypeFormData.name"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationname">
                    {{cvName2Label}}</label>
                  <input
                    v-model="vmGPathCareertypeFormData.name2"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationkey">
                    {{cvKeyLabel}}</label>
                  <input
                    v-model="vmGPathCareertypeFormData.career_key"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationtitle">
                    {{cvTitleLabel}}</label>
                  <input
                    v-model="vmGPathCareertypeFormData.title"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdetail">
                    {{cvDetailLabel}}</label>
                  <textarea
                    v-model="vmGPathCareertypeFormData.detail"
                    type="text"
                    class="textarea form-control"
                    required></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationkey">
                    {{cvUrlLabel}}</label>
                  <input
                    v-model="vmGPathCareertypeFormData.url"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="gpath_career_typeEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GPathCareertypes } from "../../../FackApi/api/GPathCareertype"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "GPathCareertypeEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGPathCareertypeObj: {
      type: Object,
      default: function () {
        return {
          "name": "",
          "name2": "",
          "career_key": "",
          "title": "",
          "detail": "",
          "url": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "gpath_career_type_edit",
      cvCardTitle: "Edit GPaths Career Type",
      cvCardSubHeader: "Edit GPaths Career Type",
      cvSubmitBtn: "Edit",
      cvNameLabel: "name",
      cvName2Label: "name2",
      cvKeyLabel: "key",
      cvTitleLabel: "Title",
      cvDetailLabel: "Detail",
      cvUrlLabel: "Url",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPaths Career Type Edit",
      vmGPathCareertypeFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.gpath_career_typeView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathCareertypeFormData) {
          if (!this.vmGPathCareertypeFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * gpath_career_typeView
     */
    async gpath_career_typeView () {
      try {
        if (this.propOpenedInModal) {
          this.vmGPathCareertypeFormData = this.propGPathCareertypeObj
        }
        else {
          let gpathCareerTypeId = this.$route.params.gpct_id
          let gpathCareerTypeViewResp = await GPathCareertypes.gpath_career_typeView(this, gpathCareerTypeId)

          if (gpathCareerTypeViewResp && gpathCareerTypeViewResp.resp_status) {
            this.vmGPathCareertypeFormData = gpathCareerTypeViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_typeView() and Exception:", err.message)
      }
    },
    /**
     * gpath_career_typeEdit
     */
    async gpath_career_typeEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let gpathCareerTypeAddResp = await GPathCareertypes.gpath_career_typeEdit(this, this.vmGPathCareertypeFormData)
        await ApiResponse.responseMessageDisplay(this, gpathCareerTypeAddResp)

        if (gpathCareerTypeAddResp && !gpathCareerTypeAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGPathCareertypeEditModal", this.vmGPathCareertypeFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_typeEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
